import BaseScheme, {BaseSchemeType} from "./BaseScheme";
import BreadcrumbListScheme, {Breadcrumb, BreadcrumbListSchemeType} from "./BreadcrumbListScheme";

type MainEntityOfPage = {
  "@type": "WebPage",
  "@id": string
}

type Author = {
  '@type': 'Person',
  name: string
}

type Publisher = {
  "@type": string,
  "name": string,
  "logo": {
    "@type": string,
    "url": string
  }
}

type NewsArticleType = BaseSchemeType & {
  "@type": "NewsArticle"
  mainEntityOfPage: MainEntityOfPage
  headline: string
  description: string
  image: string
  author: Author
  publisher: Publisher
  breadcrumb: BreadcrumbListSchemeType
}

class NewsArticleScheme extends BaseScheme<NewsArticleType> {
  constructor(private path: string) {
    super(path, true);
    super.set('@type', 'NewsArticle');

    super.set('publisher', {
      "@type": "Organization",
      "name": NewsArticleScheme.url('/'),
      "logo": {
        "@type": "ImageObject",
        "url": NewsArticleScheme.url('/images/global/logo-dfa-light.svg')
      }
    })
  }

  setBreadcrumb(value: Breadcrumb[]) {
    super.set('breadcrumb', new BreadcrumbListScheme()
      .setItemListElement(value)
      .getObject());
    return this;
  }

  setMainEntityOfPage(): this {
    super.set('mainEntityOfPage', {
      '@type': 'WebPage',
      '@id': 'https://www.df-alliance.com' + this.path
    });
    return this;
  }

  setAuthor(name: string): this {
    super.set('author', {
      '@type': 'Person',
      name
    });
    return this;
  }
}

export default NewsArticleScheme
